<template>
  <div>
    <!-- <div>
      <div class="flex">
        日期范围<el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <div class="btn-time">近一月</div>
        <div class="btn-time btn-time-active">近三月</div>
        <div class="btn-time btn-time-active">近一年</div>
      </div>
    </div> -->
    <div class="mying-box">
      <div class="mying-box-title">处置企业</div>
      <div class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div>
      <!-- <div class="mying-box-small">
        <div id="disposal-chart" style="width:100%;height:100%"></div>
      </div> -->
    </div>
    <div class="mying-box">
      <div v-if="tableData.length !== 0">
        <div class="form-search flex-v-center">
          <el-form
            :inline="true"
            :model="formSelect"
            class="demo-form-inline"
            size="small"
          >
            <el-form-item label="收运类型">
              <el-select v-model="formSelect.type" placeholder="请选择">
                <el-option label="油脂" value="1"></el-option>
                <el-option label="固渣" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收运状态">
              <el-select v-model="formSelect.status" placeholder="请选择">
                <el-option label="运输中" value="1"></el-option>
                <el-option label="已完成" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">查询</el-button>
            </el-form-item>
          </el-form>
          <div>导出报表</div>
        </div>
        <div>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="date" label="日期" width="180">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
          </el-table>
          <div class="flex-v-center" style="margin-top: 10px">
            <div></div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="5"
              prev-text="上一页"
              next-text="下一页"
              hide-on-single-page
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formSelect: {},
      total: 0,
      tableData: []
    };
  },
  mounted() {
    // this.Chart();
  },
  methods: {
    Chart() {
      let disposalChart = this.$echarts.init(
        document.getElementById("disposal-chart")
      );
      let option = {
        // backgroundColor: "#fff",

        legend: {
          show: true,
          icon: "circle",
          top: "13%",
          itemWidth: 6,
          itemHeight: 6,
          itemGap: 25
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: [
          {
            type: "category",
            data: ["A", "B", "C", "D", "E", "F", "G"],
            axisLine: {
              lineStyle: {
                color: "#ddd"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "#c56790"
              },
              margin: 15
            },
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#ddd"
              }
            },
            axisLabel: {
              textStyle: {
                color: "#c56790"
              }
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: "S",
            type: "line",
            data: [13, 10, 3, 12, 15, 30, 7],
            symbolSize: 6,
            symbol: "circle",
            smooth: true,
            lineStyle: {
              color: "#fe9a8b"
            },
            itemStyle: {
              normal: {
                color: "#fe9a8b",
                borderColor: "#fe9a8b"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#fe9a8bb3"
                },
                {
                  offset: 1,
                  color: "#fe9a8b03"
                }
              ])
            },
            emphasis: {
              itemStyle: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#fe9a8b"
                    },
                    {
                      offset: 0.4,
                      color: "#fe9a8b"
                    },
                    {
                      offset: 0.5,
                      color: "#fff"
                    },
                    {
                      offset: 0.7,
                      color: "#fff"
                    },
                    {
                      offset: 0.8,
                      color: "#fff"
                    },
                    {
                      offset: 1,
                      color: "#fff"
                    }
                  ]
                },
                borderColor: "#fe9a8b",
                borderWidth: 2
              }
            }
          },
          {
            name: "M",
            type: "line",
            data: [5, 12, 11, 4, 25, 16, 1],
            symbolSize: 6,
            symbol: "circle",
            smooth: true,
            lineStyle: {
              color: "#9E87FF"
            },
            itemStyle: {
              normal: {
                color: "#9E87FF",
                borderColor: "#9E87FF"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#9E87FFb3"
                },
                {
                  offset: 1,
                  color: "#9E87FF03"
                }
              ])
            },
            emphasis: {
              itemStyle: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#9E87FF"
                    },
                    {
                      offset: 0.4,
                      color: "#9E87FF"
                    },
                    {
                      offset: 0.5,
                      color: "#fff"
                    },
                    {
                      offset: 0.7,
                      color: "#fff"
                    },
                    {
                      offset: 0.8,
                      color: "#fff"
                    },
                    {
                      offset: 1,
                      color: "#fff"
                    }
                  ]
                },
                borderColor: "#9E87FF",
                borderWidth: 2
              }
            }
          }
        ]
      };
      // 绘制图表
      disposalChart.setOption(option);

      window.addEventListener("resize", () => {
        disposalChart.resize();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.margin-b-10 {
  margin-bottom: 10px;
}
.margin-r-10 {
  margin-right: 10px;
}
.flex-v-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mying-box {
  padding: 20px;
  background: rgba(54, 59, 110, 0.3);
  border: 1px solid #3d4283;
  border-radius: 5px;
  margin-bottom: 20px;
}
.mying-box-title {
  font-size: 18px;
  color: rgba(93, 229, 245, 1);
  margin-bottom: 20px;
}
.mying-box-small {
  height: 360px;
  background: #363b6e;
  border: 1px solid #3d4283;
  border-radius: 5px;
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 20px;
  }
}
</style>
